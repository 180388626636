import React, { useEffect } from "react"
import { parse } from "query-string"

export default function RedirectCancel() {
  useEffect(() => {
    const { subscription_id: subscription } = parse(window.location.search)
    window.location.replace("/proxies/manage?id=" + subscription)
  }, [])

  return <div />
}
